<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form inline size="small">
          <el-form-item label="车队简称">
            <el-input
              placeholder="请输入关键词"
              v-model="conditions.shortname"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="车队名称">
            <el-input
              placeholder="请输入关键词"
              v-model="conditions.name"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="small"
              class="buttons"
              @click="handleSearch"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item style="float: right">
            <el-button
              type="primary"
              size="small"
              class="buttons"
              @click="goAdd"
              >新增车队</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table" style="border: none">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="shortname"
            label="车队简称"
          >
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="name" label="车队名称">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="telephone"
            label="联系电话"
          >
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="address" label="地址">
          </el-table-column>
          <el-table-column show-overflow-tooltip label="状态">
            <template v-slot="scope">
              <el-tag
                size="small"
                :type="enumStatus[scope.row.fleetStatus].type"
                >{{ enumStatus[scope.row.fleetStatus].label }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="操作">
            <template v-slot="scope">
              <el-button
                type="text"
                size="mini"
                v-if="scope.row.fleetStatus == 1"
                @click="changeStatus(scope.row.id, 3)"
              >
                禁用
              </el-button>
              <el-button
                type="text"
                size="mini"
                v-if="scope.row.fleetStatus == 3"
                @click="changeStatus(scope.row.id, 1)"
              >
                启用
              </el-button>
              <el-button
                type="text"
                size="mini"
                @click.stop="goEdit(scope.row)"
              >
                修改
              </el-button>
              <el-button
                type="text"
                size="mini"
                @click="handleDelete(scope.row.id)"
                style="color: rgb(254, 90, 36)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="conditions.page"
          :pageSize="conditions.limit"
          @paging="getPaging"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
export default {
  data() {
    return {
      conditions: {
        page: 1,
        limit: 10,
        name: "",
        shortname: "",
      },
      enumStatus: {
        0: { label: "待审核", type: "" },
        1: { label: "正常", type: "success" },
        2: { label: "审核未通过", type: "danger" },
        3: { label: "停用", type: "info" },
      },
      tableData: [],
      total: 0,
    };
  },
  computed: {},
  components: {
    Pagination,
  },
  created() {
    this.getList();
  },
  methods: {
    indexMethod(index) {
      return (this.conditions.page - 1) * this.conditions.limit + index + 1;
    },
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    async getList() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/FleetInfo/list",
        "get",
        this.conditions
      );
      if (!data) return this.$message.error("获取数据失败");
      this.tableData = data.data.list;
      this.total = data.data.totalCount;
    },
    handleSearch() {
      this.conditions.page = 1;
      this.getList();
    },
    goAdd() {
      this.$router.push({ path: "/baseInfo/addFleet" });
    },
    goEdit(row) {
      this.$router.push({ path: "/baseInfo/addFleet", query: { id: row.id } });
    },
  },
};
</script>
<style lang="scss" scoped></style>
